import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class FormattingService {
    getBytesReadable(bytes: number, decimals = 2): string {
        if (bytes === 0) {
            return '0 B';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
