import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { PageUrlService } from '@app/shared/services/previous-url-service';

@NgModule({
    providers: [
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ActiveDirectoryWidgetsServiceProxy,
        ApiServiceProxies.ArchiveProgressServiceProxy,
        ApiServiceProxies.ArchivingSourceSummaryWidgetsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.DomainsServiceProxy,
        ApiServiceProxies.DomainUsersServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.EmailTemplatesServiceProxy,
        ApiServiceProxies.EndpointsServiceProxy,
        ApiServiceProxies.EnterpriseVaultWidgetsServiceProxy,
        ApiServiceProxies.EVArchivesServiceProxy,
        ApiServiceProxies.EVDirectoriesServiceProxy,
        ApiServiceProxies.EVFailedItemManagementServiceProxy,
        ApiServiceProxies.ExchangeDatabasesServiceProxy,
        ApiServiceProxies.ExchangeEndpointsServiceProxy,
        ApiServiceProxies.ExchangeServersServiceProxy,
        ApiServiceProxies.ExchangetWidgetsServiceProxy,
        ApiServiceProxies.FileSharesServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.JournalFinishedServiceProxy,
        ApiServiceProxies.JournalProgressServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.MailArchiveJournalMappingServiceProxy,
        ApiServiceProxies.MailArchiveLeaversMappingServiceProxy,
        ApiServiceProxies.MailArchiveMappingSelectionServiceProxy,
        ApiServiceProxies.MailArchiveMigrationMappingServiceProxy,
        ApiServiceProxies.MailArchivesFinishedServiceProxy,
        ApiServiceProxies.MailboxesServiceProxy,
        ApiServiceProxies.MigrationProcessServiceProxy,
        ApiServiceProxies.MigrationProcessWidgetsServiceProxy,
        ApiServiceProxies.MigrationProgressServiceProxy,
        ApiServiceProxies.MLXEndpointsServiceProxy,
        ApiServiceProxies.MLXMailboxesServiceProxy,
        ApiServiceProxies.MlxMappingSelectionServiceProxy,
        ApiServiceProxies.MLXMigrationProcessServiceProxy,
        ApiServiceProxies.MLXMigrationProgressesServiceProxy,
        ApiServiceProxies.MLXServersServiceProxy,
        ApiServiceProxies.MLXUserMailboxFinishedServiceProxy,
        ApiServiceProxies.MLXUserMailboxMappingSelectionServiceProxy,
        ApiServiceProxies.ModulePoolsServiceProxy,
        ApiServiceProxies.ModulesServiceProxy,
        ApiServiceProxies.ModuleStatisticsServiceProxy,
        ApiServiceProxies.ModuleToVaultStoreServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OnboardMappingSelectionServiceProxy,
        ApiServiceProxies.OnPremWidgetsServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.OverviewServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.ProgressServiceProxy,
        ApiServiceProxies.PstFilesServiceProxy,
        ApiServiceProxies.PstOutputsServiceProxy,
        ApiServiceProxies.QAMEndpointsServiceProxy,
        ApiServiceProxies.QAMMailboxesServiceProxy,
        ApiServiceProxies.QAMMailStoresServiceProxy,
        ApiServiceProxies.QamMappingSelectionServiceProxy,
        ApiServiceProxies.QAMMigrationProcessServiceProxy,
        ApiServiceProxies.QAMMigrationProgressesServiceProxy,
        ApiServiceProxies.QAMUserMailboxFinishedServiceProxy,
        ApiServiceProxies.QAMUserMailboxMappingSelectionServiceProxy,
        ApiServiceProxies.RetentionMappingServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.SharedArchiveMappingSelectionServiceProxy,
        ApiServiceProxies.SourceOne_ArchiveConnectionsServiceProxy,
        ApiServiceProxies.SourceOneArchiveProgressServiceProxy,
        ApiServiceProxies.SourceOneArchivesServiceProxy,
        ApiServiceProxies.SourceOneEndpointsServiceProxy,
        ApiServiceProxies.SourceOneFailedItemManagementServiceProxy,
        ApiServiceProxies.SourceOneJournalFinishedServiceProxy,
        ApiServiceProxies.SourceOneJournalMappingServiceProxy,
        ApiServiceProxies.SourceOneJournalProgressServiceProxy,
        ApiServiceProxies.SourceOneLeaversMappingServiceProxy,
        ApiServiceProxies.SourceOneMigrationProcessServiceProxy,
        ApiServiceProxies.SourceOneProgressesServiceProxy,
        ApiServiceProxies.SourceOneUserMailboxMappingSelectionServiceProxy,
        ApiServiceProxies.SourceOneWidgetsServiceProxy,
        ApiServiceProxies.StagingCachesServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantCustomizationServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.VaultStoresServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.EntityChangeServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.WidgetChartsServiceProxy,
        ApiServiceProxies.OnpremServicesServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        PageUrlService

    ]
})
export class ServiceProxyModule {}
