import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private source = new BehaviorSubject('');

    routeList = this.source.asObservable();

    constructor() { }

    currentRoute(link: string) {
        this.source.next(link);
    }
}
